import gql from "graphql-tag";

export const OnNewUserSignUp = gql`
    subscription onNewUserSignUp{ 
        onNewUserSignUp{ 
            ChainAvailableAmount{ 
                userName
            data{ 
                currency
                value
            }
            }
        }
    }
`;