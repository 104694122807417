import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    labeled: {
      color: "#212226",
      fontFamily: "Poppins, sans-serif",
      fontSize: 14,
      lineHeight: "24px",
      margin: 0,
    },
  })
);
