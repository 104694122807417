import React, { useState } from "react";

export const UseHistory = (setOpenDateDu: any, setOpenDateAu: any) => {
  const [viewsDatePickers, setViewsDatePickers] = useState<number>(1);
  const [minDate, setMinDate] = useState<string | null | undefined>();
  const [selectedDateAu, setSelectedDateAu] = React.useState<Date>(
    new Date(Date.now())
  );
  const [selectedDateDu, setSelectedDateDu] = React.useState<Date>(
    new Date(Date.now())
  );
  const [formatDate, setFormatDate] = useState<string>("MM/yyyy");
  const [view, setView] = useState<Array<"year" | "date" | "month">>([
    "month",
    "year",
  ]);
  const [state, setState] = React.useState<{
    [key: string]: any;
  }>({
    valeur: 0,
    transfert: "all",
    transactionMethod: "all",
    userId: "",
  });

  const onChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: any;
    }>,
    _child?: React.ReactNode
  ) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "periode") {
      setViewsDatePickers(value);
      if (value === 2) {
        setSelectedDateDu(selectedDateAu);
      }
    }
  };

  const handleDateChangeAu = (
    date: any,
    _value?: string | null | undefined
  ) => {
    setSelectedDateAu(date);
    setOpenDateAu(false);
  };
  const handleDateChangeDu = (date: any, _value: string | null | undefined) => {
    setMinDate(date);
    setSelectedDateDu(date);
    setOpenDateDu(false);
  };

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as keyof typeof state;
    setState({ ...state, [name]: event.target.value });
  };

  return {
    onChange,
    selectedDateDu,
    setSelectedDateDu,
    selectedDateAu,
    setSelectedDateAu,
    view,
    setView,
    formatDate,
    setFormatDate,
    state,
    setState,
    handleChange,
    viewsDatePickers,
    setViewsDatePickers,
    handleDateChangeDu,
    handleDateChangeAu,
    minDate,
  };
};
