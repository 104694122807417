import { createStyles, makeStyles, Theme } from "@material-ui/core";
import background from "../../../assets/img/background.svg";

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      fontFamily: "Poppins, sans-serif",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundImage: `url(${background})`,
      backgroundAttachment: "fixed",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      margin: "10px auto",
      "@media (max-height: 604px)": {
        height: "100%",
      },
      "@media (max-width: 991px)": {
        backgroundImage: "initial",
        height: "100%",
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#4B7FD5",
            borderWidth: 1,
          },
        },
      },
      "& .MuiFormHelperText-root.Mui-error": {
        marginTop: "-1px",
        fontSize: "10px",
      },
    },
    cadre: {
      width: 396,
      maxHeight: 600,
      background: "#FFFFFF",
      border: "1px solid #E9EAEC",
      borderRadius: 6,
      opacity: 1,
      backgroundColor: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      padding: 30,
      "@media (max-width: 639px)": {
        padding: 20,
        width: 300,
      },
    },
    textField: {
      width: 316,
      background: "#FFFFFF",
      opacity: 1,
      height: 42,
      margin: "0 0 20px",
      "@media (max-width: 639px)": {
        width: "100%",
      },
      "&:last-of-type": {
        marginBottom: 30,
      },
      "& label": {
        color: "#A8AAB3",
        fontSize: 12,
        fontWeight: 400,
        lineHeight: "16px",
      },
      "& .MuiOutlinedInput-root": {
        "&:hover": {
          borderColor: "#B48A4E",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#B48A4E",
          },
        },
      },
      "& .MuiInputLabel-outlined": {
        transform: "translate(14px, 14px) scale(1)",
        "&.MuiInputLabel-shrink": {
          transform: "translate(14px, -5px) scale(0.75)",
        },
      },
      "& .MuiInputLabel-formControl": {
        fontFamily: "Poppins, sans-serif",
      },
      "& input": {
        borderRadius: 6,
        padding: "12px 14px",
        "&:focus": {
          outline: "none",
        },
        "&:cursor": {
          borderColor: "#B48A4E",
        },
      },
    },
    logintext: {
      fontFamily: "Poppins, sans-serif",
      fontSize: "0.875em",
      textAlign: "center",
      lineHeight: "20px",
      letterSpacing: ".25px",
      color: "#212226",
      justifyContent: "center",
      margin: "0 0 36px",
      "@media (max-width: 639px)": {
        width: "100%",
      },
    },
    btnconnect: {
      boxShadow: "none",
      fontSize: 15,
      width: 316,
      height: 42,
      borderRadius: 4,
      margin: 0,
      "@media (max-width: 639px)": {
        width: "100%",
      },
    },
    logo: {
      height: 100,
      marginBottom: 6,
      width: 160,
    },
    textTypography: {
      fontFamily: "Poppins, sans-serif",
      width: 50,
      height: 50,
      backgroundColor: " #4B7FD5",
      borderRadius: 10,
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      transform: "rotate(45deg)",
      color: "#fff",
      margin: 8,
      "& > span": {
        lineHeight: "100%",
        transform: "rotate(-45deg)",
        "& strong": {
          fontWeight: 700,
        },
      },
    },
    textBienvenue: {
      color: "#212226",
      fontFamily: "Poppins, sans-serif",
      fontSize: "1.5em",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: 0.18,
      marginBottom: 16,
      textAlign: "center",
    },
    boxContentInfo: {
      display: "flex",
      alignItems: "baseline",
      flexDirection: "column",
      marginBottom: "24px",
    },
    boxContentTitle: {
      font: "normal normal normal 12px/16px Poppins",
      color: "#212226",
      letterSpacing: 0.4,
      marginBottom: "8px",
      fontWeight: "bolder",
    },
    boxContentText: {
      font: "normal normal normal 14px/16px Poppins",
      color: "#212226",
      letterSpacing: 0.46,
    },
    boxContentItem: {
      width: "100%",
      wordBreak: "break-all",
      margin: "8px 0px 12px 0px",
    },
    link: {
      paddingTop: "16px",
      color: "#B48A4E",
    },
  })
);
