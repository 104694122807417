import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    "@global": {
      "* .MuiPopover-paper": {
        boxShadow: "0px 3px 6px #14141429",
        borderRadius: 6,
        marginTop: 4,
        "& .MuiMenu-list": {
          paddingTop: 0,
          paddingBottom: 0,
        },
        "& .MuiListItem-root": {
          paddingTop: 16,
          paddingBottom: 16,
          color: "#212226",
          fontFamily: "Poppins, sans-serif",
          fontSize: 12,
          letterSpacing: ".4px",
          lineHeight: "16px",
        },
      },
    },
    root: {
      //width: "89vw",
    },
    selectBoxItem: {
      marginRight: 20,
      marginBottom: 10,
      "@media (max-width: 1199px)": {
        marginRight: 0,
        marginBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        width: "33.33333%",
      },
      "@media (max-width: 767px)": {
        width: "50%",
      },
      "@media (max-width: 575px)": {
        width: "100%",
      },
      "& h6": {
        color: "rgba(0, 0, 0, 0.87)",
        fontFamily: "Poppins, sans-serif",
        fontSize: 12,
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: 0.25,
        margin: 0,
      },
      "& .MuiSelect-selectMenu": {
        background: "none",
      },
    },

    formControl: {
      //width: 237,
      backgroundColor: "#ffffff",
      borderRadius: 6,
      border: "1px solid #babdc3",
      fontFamily: "Poppins, sans-serif",
      height: 42,
      "@media (max-width: 1199px)": {
        width: "100%",
      },
      "&:focus": {
        background: "none",
        borderColor: "#B48A4E",
      },
      "&:hover": {
        background: "none",
        borderColor: "#B48A4E",
      },
      "&.MuiInputBase-root": {
        width: "100%",
      },
      "&.MuiFilledInput-root": {
        "&:focus": {
          background: "none",
        },
      },

      "& .MuiFilledInput-underline": {
        "&:focus": {
          outline: "none",
        },
        "&:before": {
          display: "none",
        },
        "& :after": {
          display: "none",
          border: "none",
        },
      },
      "& .MuiFilledInput-input": {
        padding: 0,
        "&.Mui-focused": {
          background: "none",
        },
      },

      "& .MuiFormLabel-root": {
        display: "none",
        fontFamily: "Poppins, sans-serif",
        fontSize: 12,
        fontWeight: "400",
        lineHeight: "16px",
        letterSpacing: "0.4px",
        "&.Mui-focused": {
          display: "none",
        },
      },
      "& .MuiSelect-select": {
        fontFamily: "Poppins, sans-serif",
        fontSize: 12,
        fontWeight: "400",
        lineHeight: "16px",
        letterSpacing: "0.4px",
      },
      "& .MuiOutlinedInput-input": {
        padding: "13px 14px",
      },
      "& .MuiSelect-outlined": {
        borderRadius: 6,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        display: "none",
        borderColor: "#E9EAEC",
      },
      "& .MuiSelect-nativeInput": {
        border: "none",
      },
      "& .MuiSelect-icon": {
        color: "#000",
      },
    },
    selectEmpty: {},
    select: {},
  })
);
