import { List, ListItem, ListItemText } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { ExitToApp } from "@material-ui/icons";
import DashboardIcon from "@material-ui/icons/Dashboard";
import HistoryIcon from "@material-ui/icons/History";
import clsx from "clsx";
import { MdOutlineQuiz } from "react-icons/md";
import React, { FC, useContext } from "react";
import { NavLink, RouteComponentProps, useHistory } from "react-router-dom";
import { ACCUEIL_URL, HISTORY_URL, FAQ_URL } from "../../../../constant/url";
import { AuthContext } from "../../../../provider/Authprovider";
import { Balances } from "../Balance/Balances";
import FAQ from "../FAQ/FAQ";
import { Histories } from "../Historique/History";
import { useStyles } from "./style";
import logoC4W from "../../../../assets/logo/logo_chain4test.svg";

export const PageAccueil: FC<RouteComponentProps> = ({
  location: { pathname },
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { logout } = useContext(AuthContext);

  const showAccueil: boolean = pathname === "/accueil";
  const showHisitorique: boolean = pathname === "/history";
  const showFAQ: boolean = pathname === "/faq";

  const goTo = (url: string) => {
    history.push(url);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.leftColumn}>
        <Box>
          <NavLink to={ACCUEIL_URL}>
            <Box className={classes.logo}>
              <img src={logoC4W} alt="C4W" />
            </Box>
          </NavLink>
          <Box className={classes.listMenu}>
            <List
              className={classes.listMenuCntr}
              component="nav"
              aria-label="main"
            >
              <ListItem
                button
                className={clsx(
                  classes.menuItem,
                  showAccueil ? classes.activ : classes.inactive
                )}
                onClick={() => goTo(ACCUEIL_URL)}
              >
                <Box className="menuCnt">
                  <DashboardIcon />
                  <ListItemText primary="Accueil" />
                </Box>
              </ListItem>
              <ListItem
                button
                className={clsx(
                  classes.menuItem,
                  showHisitorique ? classes.activ : classes.inactive
                )}
                onClick={() => goTo(HISTORY_URL)}
              >
                <Box className="menuCnt">
                  <HistoryIcon />
                  <ListItemText primary="Historique" />
                </Box>
              </ListItem>
              <ListItem
                button
                className={clsx(
                  classes.menuItem,
                  showFAQ ? classes.activ : classes.inactive
                )}
                onClick={() => goTo(FAQ_URL)}
              >
                <Box className="menuCnt">
                  <MdOutlineQuiz size={25} />
                  <ListItemText primary="FAQ" />
                </Box>
              </ListItem>
            </List>
          </Box>
        </Box>
        <Box className={classes.deconnect} onClick={logout}>
          <Box className={classes.deconnectCnt}>
            <ExitToApp />
            <ListItemText primary="Déconnexion" />
          </Box>
        </Box>
      </Box>
      <Box className={classes.rightColumn}>
        {showAccueil ? (
          <Balances />
        ) : showHisitorique ? (
          <Histories />
        ) : showFAQ ? (
          <FAQ />
        ) : (
          <Typography variant="h4" className={classes.noFound}>
            Page introuvable
          </Typography>
        )}
      </Box>
    </Box>
  );
};
