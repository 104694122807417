import { useQuery } from "@apollo/client";
import React, { createContext, FC, useState } from "react";
import { CustomBackdrop } from "../common/BackDrop/BackDrop";
import { CustomSnackBar } from "../common/CustomSnackBar/CustomSnackBar";
import { TOKEN } from "../constant";
import { DO_GET_ME } from "../graphql/Me/query";
import { Me } from "../graphql/Me/__generated__/Me";
import { setUser } from "./localesStorage";

interface AuthContextInterface {
  login: (token: string) => void;
  logout: () => void;
  token: string | null;
  onShowSnackBar: (
    message: string,
    typeSnackBar: string,
    openSnack: boolean
  ) => void;
}

export const AuthContext = createContext<AuthContextInterface>({
  login: () => {},
  logout: () => {},
  token: "",
  onShowSnackBar: () => {},
});

export const AuthProvider: FC = ({ children }) => {
  const [token, settoken] = useState<string | null>(null);
  const [openSnack, setOpenSnack] = useState(false);
  const [message, setmessage] = React.useState("");
  const [typeSnack, settypeSnack] = React.useState("");

  const { data: dataMe, loading: loadingMe, refetch } = useQuery<Me>(DO_GET_ME);

  if (dataMe) {
    setUser(dataMe.me);
  }
  if (loadingMe) {
    return <CustomBackdrop {...{ open: loadingMe }} />;
  }
  return (
    <AuthContext.Provider
      {...{
        value: {
          login: (token) => {
            localStorage.setItem(TOKEN, token);
            localStorage.setItem("isFirstTab", "false");
            settoken(token);
            refetch();
          },
          logout: () => {
            localStorage.removeItem(TOKEN);
            localStorage.clear();
            settoken(null);
            window.location.reload();
          },
          onShowSnackBar: (message, typeSnack, boolean) => {
            setOpenSnack(boolean);
            setmessage(message);
            settypeSnack(typeSnack);
          },

          token,
        },
      }}
    >
      <CustomSnackBar
        {...{
          open: openSnack,
          setOpen: setOpenSnack,
          message: message,
          type: typeSnack,
        }}
      />
      {children}
    </AuthContext.Provider>
  );
};
