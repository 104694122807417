import { gql } from "@apollo/client";

export const TRANSACTION_FRAGMENTS_INFO = gql`
  fragment transactionInfo on TransactionResult {
    total
    data {
      id
      transactionMethod
      transaction_hash
      createdAt
      from
      to
      label
      amount
      currency
      sender {
        id
        name
        lastName
      }
      recipient {
        id
        name
        lastName
      }
    }
  }
`;

export const MAKE_TRANSACTION_FRAGMENTS_INFO = gql`
  fragment make_transactionInfo on TransactionHistory {
    id
    transactionMethod
    transaction_hash
    createdAt
    from
    to
    label
    amount
    currency
    sender {
      id
      name
      lastName
    }
    recipient {
      id
      name
      lastName
    }
  }
`;
