import {
  Card,
  CardContent,
  Box,
  Typography,
  CardActions,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core";
import { ArrowRightAlt } from "@material-ui/icons";
import React, { FC } from "react";
import { CustomButton } from "../../../../common/CustomButton/CustomButton";
import Coin from "../../../../assets/img/Coin.png";
import CCoin from "../../../../assets/img/CCoin.png";
import CCCoin from "../../../../assets/img/CCCoin.png";
import { lowerCase } from "lodash";
import { formatAmountLetter } from "../../../../utils/helpers";
interface cartProps {
  style: any;
  devise: string;
  value: string;
  handleClick?: (value: any) => void;
}

const Cart: FC<cartProps> = ({ style, devise, value, handleClick }) => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#B48A4E",
      },
    },
  });

  const logoDevise = new Map();

  logoDevise.set("Euro", "EUR");
  logoDevise.set("CCoins", "C");
  logoDevise.set("CCCoins", "C");
  logoDevise.set("Coins", "C");
  logoDevise.set("Dollar Canadien", "CAD");
  logoDevise.set("", "C");

  const logoColor = new Map();
  logoColor.set("Euro", "#FF8552");
  logoColor.set("Dollar Canadien", "#02C39A");
  logoColor.set("", "#FFFFFF");

  const logoBackground = new Map();
  logoBackground.set("Euro", "#FFEBCD");
  logoBackground.set("Dollar Canadien", "#98FB98");
  logoBackground.set("", "#4B7FD5");

  const namelogo =
    lowerCase(devise) === "1"
      ? Coin
      : lowerCase(devise) === "2"
      ? CCoin
      : lowerCase(devise) === "3"
      ? CCCoin
      : Coin;
  const nameDevise = new Map();
  nameDevise.set("1", "Coins");
  nameDevise.set("2", "CCoins");
  nameDevise.set("3", "CCCoins");
  nameDevise.set("4", "Dollar Canadien");
  nameDevise.set("5", "EURO");
  return (
    <>
      <Card className={style.cardItem}>
        <CardContent className={style.cardContainer}>
          <Box
            className={style.logocontaint}
            style={{
              backgroundColor: logoBackground.get(devise)
                ? logoBackground.get(devise)
                : "#4B7FD5",
              color: logoColor.get(devise) ? logoColor.get(devise) : "#FFFFFF",
              fontSize: "18px",
            }}
          >
            <img
              {...{
                src: namelogo,
                alt: "logo ccoin",
                className: style.imglogo,
              }}
            />
          </Box>
          <Typography variant="body2" component="p" className={style.info_txt}>
            <span className="devise">{nameDevise.get(devise)}</span>
            <span className="value">
              {formatAmountLetter(
                new Intl.NumberFormat("fr-FR").format(Number(value))
              )}
            </span>
          </Typography>
        </CardContent>
        <CardActions className={style.actionBloc}>
          <ThemeProvider theme={theme}>
            <CustomButton
              {...{
                nameBtn: "TRANSFÉRER",
                color: "primary",
                variant: "contained",
                className: style.btnTransfert,
                onClick: handleClick,
                endIcon: <ArrowRightAlt />,
              }}
            />
          </ThemeProvider>
        </CardActions>
      </Card>
    </>
  );
};

export default Cart;
