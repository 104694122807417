import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    "@global": {
      "* .scrollBarWrap": {
        maxHeight: "calc(100vh - 220px)",
        "@media (min-height: 700px)": {
          maxHeight: "100%",
          //maxHeight: "calc(100vh - 220px)",
        },
        "& .simplebar-track": {
          pointerEvents: "auto",
        },
      },
      "* .scrollBar": {
        "&::-webkit-scrollbar": {
          backgroundColor: "transparent",
          //backgroundColor: "rgba(0,0,0,.15)",
          borderRadius: ".4rem",
          width: 0,
          //width: ".4rem",
        },
        "&::-webkit-scrollbar-track": {
          "-webkit-box-shadow": "none",
          //"-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "transparent",
          //backgroundColor: "rgba(0,0,0,.25)",
          borderRadius: ".4rem",
          outline: "none",
        },
      },

      //".MuiPickersBasePicker-container": {},
      ".MuiPickersBasePicker-container": {
        "& *": {
          fontFamily: "Poppins, sans-serif",
        },
        "& .MuiPickersToolbar-toolbar": {
          backgroundColor: "#4B7FD5",
          height: 87,
        },
        "& .MuiPickersToolbarText-toolbarTxt": {
          color: "rgba(255,255,255,.7)",
          lineHeight: "1.56rem",
        },
        "& .MuiPickersToolbarText-toolbarBtnSelected": {
          color: "#fff",
          lineHeight: "51px",
        },

        "& .MuiPickersCalendarHeader-dayLabel": {
          color: "rgba(0, 0, 0, .26)",
          fontSize: ".75rem",
        },
        "& .MuiPickersDay-day": {
          color: "#000",
          fontSize: ".75rem",
        },
        "& .MuiPickersDay-daySelected": {
          backgroundColor: "#4B7FD5",
          color: "#fff",
        },
        // "& .MuiTypography-body1": {
        //   color: "#212226",
        //   fontSize: ".875rem",
        // },
      },
    },
    root: {
      //width: "89vw",
    },
    rootContainer: {
      margin: 0,
      padding: "22px 28px",
      width: "100%",
      "@media (max-width: 575px)": {
        padding: "11px 14px",
      },
    },
    wrapHeader: {
      flex: "1 0 105px",
      marginBottom: 20,
      "@media (max-width: 575px)": {
        marginBottom: 10,
      },
    },
    boxheader: {
      display: "flex",
      flex: "1",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-end",
      marginBottom: 22,
      "@media (max-width: 575px)": {
        marginBottom: 12,
      },
      "& .userProfil": {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
      },
      "& .user_name": {
        "& h5, & h4": {
          color: "rgba(0, 0, 0, 0.87)",
          fontFamily: "Poppins, sans-serif",
        },
        "& h5": {
          fontSize: 12,
          lineHeight: "16px",
          fontWeight: 400,
          margin: "0",
        },
        "& h4": {
          fontWeight: 500,
          fontSize: 16,
          lineHeight: "24px",
          margin: "0",
          "@media (max-width: 575px)": {
            fontSize: 12,
            fontWeight: 700,
          },
        },
      },
      "& .user_avatar": {
        border: "1px solid #A8AAB3",
        borderRadius: 12,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 40,
        height: 40,
        marginLeft: 17,
        "& .user-icon": {
          maxWidth: 16,
        },
      },
    },
    boxTitle: {
      display: "flex",
      alignItems: "baseline",
    },
    boxId: {
      margin: "0 0 0 12px",
    },
    boxintro: {
      "& h2, & h3": {
        fontFamily: "Poppins, sans-serif",
        fontWeight: 400,
        margin: 0,
      },
      "& h2": {
        color: "#212226",
        fontSize: 16,
        fontWeight: 500,
        lineHeight: "24px",
        "@media (max-width: 575px)": {
          fontSize: 14,
        },
      },
      "& h3": {
        color: "#212226",
        fontSize: 12,
        fontWeight: 400,
        lineHeight: "16px",
        "@media (max-width: 575px)": {
          fontSize: 11,
        },
      },
    },
    textAccueil: {
      color: "#B48A4E",
      fontSize: 34,
      fontFamily: "Poppins, sans-serif",
      fontWeight: 600,
      lineHeight: "36px",
      "@media (max-width: 575px)": {
        fontSize: 24,
      },
    },
    "& transfertWrap": {
      paddingTop: 20,
    },
    transfert_list: {
      //marginBottom: 20,
      //marginTop: 20,
      overflowX: "hidden",
      //overflowY: "auto",
      //height: "calc(100vh - 170px)",
    },
    transfert_innerlist: {
      height: "auto",
    },
    textResult: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    cardItem: {
      border: "1px solid #e9eaec",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      boxShadow: "none",
      height: 90,
      margin: "0 0 16px",
      padding: "10px 20px",
      justifyContent: "space-between",
      "@media (max-width: 575px)": {
        flexDirection: "column",
        justifyContent: "center",
        height: "auto",
        marginBottom: 10,
        padding: "10px",
      },
      "&:last-of-type": {
        marginBottom: 0,
      },
      "& .MuiPaper-rounded": {
        borderRadius: 6,
      },
    },
    cardContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 0,
      "@media (max-width: 575px)": {
        marginBottom: 10,
      },
    },
    logocontaint: {
      width: 50,
      minWidth: 50,
      height: 50,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      borderRadius: 15,
      marginRight: 18,
      "@media (max-width: 575px)": {
        marginRight: 14,
        width: 40,
        minWidth: 40,
        height: 40,
      },
    },
    info_txt: {
      "& span": {
        display: "block",
      },
      "& .devise": {
        color: "#686B78",
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: "0.1px",
        lineHeight: "24px",
        "@media (max-width: 575px)": {
          fontSize: 12,
        },
      },
      "& .value": {
        color: "#212226",
        fontSize: 20,
        lineHeight: "24px",
        fontWeight: 600,
        "@media (max-width: 575px)": {
          fontSize: 16,
        },
      },
    },
    actionBloc: {
      height: "100%",
      padding: "0",
      borderLeft: "1px solid #E9EAEC",
      "@media (max-width: 575px)": {
        borderLeft: "none",
      },
      "&.MuiCardActions-spacing": {
        paddingLeft: 29,
        "@media (max-width: 575px)": {
          paddingLeft: 0,
        },
      },
    },
    btnTransfert: {
      boxShadow: "none",
      color: "#fff",
      fontSize: 15,
      lineHeight: "16px",
      height: 42,
      minWidth: 200,
      "@media (max-width: 575px)": {
        minWidth: 150,
        height: 36,
      },
      "& .MuiButton-endIcon": {
        marginLeft: 14,
        marginRight: 0,
      },
    },
    iconUser: {
      width: 40,
      height: 75,
      marginRight: 27,
      marginLeft: 20,
      borderRadius: 12,
      border: "1 solid",
    },
    imglogo: {
      width: 50,
      height: 50,
      border: "1px solid #8170DA",
      borderRadius: 16,
    },

    ribbon: {
      backgroundColor: "skyblue",
      position: "absolute",
      color: "white",
      width: 35,
      height: 16,
      zIndex: 3,
      textAlign: "center",
      textTransform: "uppercase",
      padding: 4,

      "&::before": {
        position: "absolute",
        zIndex: -1,
        content: "",
        display: "block",
        border: "5px solid #2980b9",
      },
      "&::after": {
        position: "absolute",
        zIndex: -1,
        content: "",
        display: "block",
        border: "5px solid #2980b9",
      },
      transform: "rotate(45deg)",
      top: 30,
      marginLeft: 400,
    },

    boxStatus: {
      height: "auto",
      marginBottom: 10,
      textAlign: "center",
      "@media (min-width: 768px)": {
        display: "flex",
        justifyContent: "center",
      },
      "& h6": {
        fontSize: 12,
        fontWeight: "700",
        lineHeight: "120%",
        margin: "0 0 6px",
        "@media (min-width: 480px)": {
          fontSize: 14,
        },
        "@media (min-width: 768px)": {
          margin: 0,
        },
      },
      "& .wraplist": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      },
      "& .list": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: 5,
        "& p": {
          fontSize: 12,
          fontWeight: "400",
          lineHeight: "120%",
          margin: 0,
          "@media (min-width: 480px)": {
            fontSize: 14,
          },
        },
      },
    },

    separator: {
      paddingLeft: "5px",
      paddingRight: "12px",
    },
    separatorText: {
      paddingLeft: "12px",
    },
  })
);
