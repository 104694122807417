/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Methode de transfert
 */
export enum TransactionMethod {
  AVANCE_SOLIDAIRE = "AVANCE_SOLIDAIRE",
  DON_APPORT = "DON_APPORT",
  INITIALISATION = "INITIALISATION",
  PAYER = "PAYER",
  TRANSFERT_CYTOIENS = "TRANSFERT_CYTOIENS",
  VERSEMENT_SALAIRE = "VERSEMENT_SALAIRE",
}

export interface GetTransactionArgs {
  currency?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  skip?: number | null;
  take?: number | null;
  transactionType?: string | null;
  transactionMethod?: TransactionMethod | null;
  userId?: string | null;
}

export interface TransactionInput {
  amount: number;
  userRecipientId: string;
  transactionMethod: TransactionMethod;
  currencyId: number;
}

export interface loginInput {
  id: string;
  password: string;
}

export interface signUpInput {
  lastName: string;
  firstName: string;
  id: string;
  password: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
