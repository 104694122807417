import { useMutation } from "@apollo/client/react/hooks/useMutation";
import {
  Box,
  createMuiTheme,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CustomButton } from "../../../../common/CustomButton/CustomButton";
import { CustomSnackBar } from "../../../../common/CustomSnackBar/CustomSnackBar";
import { TEXT_DUPLICATED_NAME } from "../../../../constant";
import { ACCUEIL_URL } from "../../../../constant/url";
import { useStyles } from "../../homepage/style";
import logoC4W from "../../../../assets/logo/logo_chain4test_complet.svg";
import { login, loginVariables } from "../../../../graphql/Login/types/login";
import { DO_SIGNIN } from "../../../../graphql/Login/mutation";
import { AuthContext } from "../../../../provider/Authprovider";
import { CustomBackdrop } from "../../../../common/BackDrop/BackDrop";
import {
  capitalizeFirstLetter,
  formatUsernameWithCountryCode,
} from "../../../../utils/helpers";

interface CardSignUpAccessProps {
  id: string;
  password: string;
  duplicatedName: string;
}
const CardSignUpAccess: React.FC<CardSignUpAccessProps> = (props) => {
  const { login } = useContext(AuthContext);
  const style = useStyles();
  const { id, password, duplicatedName } = props;
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [type, setType] = useState<string>("");
  let history = useHistory();

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#B48A4E",
      },
    },
  });

  const [doSignIn, { loading: doSignInLoading }] = useMutation<
    login,
    loginVariables
  >(DO_SIGNIN, {
    onCompleted: (data) => {
      setOpen(true);
      setType("success");
      setMessage("Authentification reussi");
      if (data.login.accessToken) {
        login(data.login.accessToken);
      }
      history.push(ACCUEIL_URL);
    },
    onError: (errors) => {
      setOpen(true);
      setType("error");
      if (id === "" || password === "") {
        setMessage("Veuillez completer tous les champs!");
      } else {
        errors?.graphQLErrors?.map((error) => {
          setMessage(error.message);
          return error;
        });
      }
    },
  });

  const handleClick = () => {
    doSignIn({
      variables: {
        userLoginInput: {
          id,
          password,
        },
      },
    });
  };

  useEffect(() => {
    if (duplicatedName.length === 0) {
      doSignIn({
        variables: {
          userLoginInput: {
            id,
            password,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={style.root}>
      <Box className={style.cadre}>
        <div className={style.logo}>
          <img src={logoC4W} alt="C4W" />
        </div>
        {duplicatedName.length && (
          <>
            <Typography variant="h4" className={style.textBienvenue}>
              Votre identité existe déjà !
            </Typography>
            <Typography className={style.logintext}>
              {TEXT_DUPLICATED_NAME +
                " " +
                formatUsernameWithCountryCode(
                  capitalizeFirstLetter(duplicatedName)
                )}
            </Typography>

            <ThemeProvider theme={theme}>
              <CustomButton
                {...{
                  nameBtn: "FERMER",
                  color: "primary",
                  variant: "contained",
                  className: style.btnconnect,
                  onClick: handleClick,
                }}
              />
            </ThemeProvider>
          </>
        )}
      </Box>
      <CustomSnackBar
        {...{ open: open, setOpen: setOpen, message: message, type: type }}
      />
      <CustomBackdrop {...{ open: doSignInLoading }} />
    </Box>
  );
};

export default CardSignUpAccess;
