import gql from "graphql-tag";

export const OnBalanceUpdate = gql`
  subscription onBalanceUpdate($address: String) {
    onBalanceUpdate(address: $address) {
      balance {
        currency
        value
      }
    }
  }
`;
