import gql from "graphql-tag";
import { MAKE_TRANSACTION_FRAGMENTS_INFO } from "./fragment";

export const OnHistoryUpdate = gql`
  subscription onHistoryUpdate($address: String) {
    onHistoryUpdate(address: $address) {
      ...make_transactionInfo
    }
  }
  ${MAKE_TRANSACTION_FRAGMENTS_INFO}
`;
