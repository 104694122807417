import gql from "graphql-tag";
import { USERS_FRAGMENTS_INFO } from "./fragment";

export const DO_GET_USERS = gql`
  query Get_Users {
    users {
      ...UsersInfo
    }
  }
  ${USERS_FRAGMENTS_INFO}
`;

export const DO_GET_USER = gql`
  query Get_User($id: String!) {
    user(id: $id) {
      ...UsersInfo
    }
  }
  ${USERS_FRAGMENTS_INFO}
`;