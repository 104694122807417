import gql from "graphql-tag";
import { MAKE_TRANSACTION_FRAGMENTS_INFO } from "./fragment";

export const DO_MAKE_TRANSACTION = gql`
  mutation MAKE_TRANSACTION($transactionInput: TransactionInput) {
    makeTransaction(transactionInput: $transactionInput) {
      ...make_transactionInfo
    }
  }
  ${MAKE_TRANSACTION_FRAGMENTS_INFO}
`;
