import { useMutation } from "@apollo/client/react/hooks/useMutation";
import {
  Box,
  TextField,
  Typography,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core";
import { useStyles } from "../../homepage/style";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TEXT_SIGN_UP, TOKEN } from "../../../../constant";
import { getUser } from "../../../../provider/localesStorage";
import { DO_SIGNUP } from "../../../../graphql/SignUp/mutation";
import { ACCUEIL_URL, LOGIN_URL } from "../../../../constant/url";
import { CustomBackdrop } from "../../../../common/BackDrop/BackDrop";
import { CustomButton } from "../../../../common/CustomButton/CustomButton";
import { CustomSnackBar } from "../../../../common/CustomSnackBar/CustomSnackBar";
import {
  signUp,
  signUpVariables,
} from "../../../../graphql/SignUp/__generated__/signUp";
import logoC4W from "../../../../assets/logo/logo_chain4test_complet.svg";
import { removeAccents } from "../../../../utils/helpers";

interface signUpProps {
  lastName: string;
  firstName: string;
  id: string;
  password: string;
}
interface CardSinUpProps {
  setData: (value: any) => void;
}
const CardSignUp: React.FC<CardSinUpProps> = (props) => {
  const user = getUser();
  const { setData } = props;
  const style = useStyles();
  let history = useHistory();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [activ, setactiv] = useState<boolean>(false);
  const [input, setInput] = useState<signUpProps>({
    lastName: "",
    firstName: "",
    id: "",
    password: "",
  });
  const [idError, setIdError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#B48A4E",
      },
    },
  });

  const [doSignUp, { loading: doSignUpLoading }] = useMutation<
    signUp,
    signUpVariables
  >(DO_SIGNUP, {
    onCompleted: (data) => {
      setOpen(true);

      if (data.signUp && data.signUp.data) {
        setType("success");
        setMessage("Création de compte réussi");
        setData({
          ...data.signUp.data,
          duplicatedName: data.signUp.data.name,
        });
      } else {
        setType("error");
        setMessage((data && data.signUp.message) || "");
      }
    },
    onError: (errors) => {
      setOpen(true);
      setType("error");
      if (input.firstName === "" || input.lastName === "") {
        setMessage("Veuillez completer tous les champs!");
      } else {
        errors?.graphQLErrors?.map((error) => {
          setMessage(error.message);
          return error;
        });
      }
    },
  });

  const handleClick = () => {
    const { firstName, lastName, id, password } = input;
    if (input.id.length < 6 || input.id.length > 20) {
      setOpen(true);
      setType("error");
      setMessage(
        "L’identifiant de connexion choisi est incorrect : 6 à 20 caractères sont obligatoires. Recommencez s’il vous plaît."
      );
    } else if (isNaN(+input.password) || input.password.length !== 6) {
      setOpen(true);
      setType("error");
      setMessage(
        "Le mot de passe choisi est incorrect : 6 chiffres sont obligatoires. Recommencez s’il vous plaît."
      );
    } else {
      doSignUp({
        variables: {
          signUpInput: {
            firstName: removeAccents(firstName.trim() || ""),
            lastName: removeAccents(lastName.trim() || ""),
            id: id.trim() || "",
            password: password.trim() || "",
          },
        },
      });
    }
  };

  const onKeyPressed = (e: any) => {
    if (e.key === "Enter") {
      if (
        input.lastName !== "" &&
        input.firstName !== "" &&
        input.id !== "" &&
        input.password !== ""
      ) {
        handleClick();
      } else {
        setOpen(true);
        setType("error");
        setMessage("Veuillez completer tous les champs!");
      }
    }
  };

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = String(event.target.value);
    setInput((prevstate) => ({ ...prevstate, [name]: value }));
  };

  useEffect(() => {
    if (
      !/^[a-zA-Z\u00C0-\u00FF0-9 ]*$/.test(
        `${input.lastName} ${input.firstName}`
      ) &&
      (input.lastName !== "" || input.firstName !== "")
    ) {
      setactiv(false);
      setOpen(true);
      setType("error");
      setMessage("Les caractères spéciaux ne sont pas autorisés");
    } else if (
      input.lastName !== "" &&
      input.firstName !== "" &&
      input.password !== "" &&
      input.id !== ""
    ) {
      setactiv(true);
    } else {
      setactiv(false);
    }
  }, [input]);

  useEffect(() => {
    if (!!localStorage.getItem(TOKEN) && user) {
      history.push(ACCUEIL_URL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={style.root}>
      <Box className={style.cadre}>
        <div className={style.logo}>
          <img src={logoC4W} alt="C4W" />
        </div>
        <Typography variant="h4" className={style.textBienvenue}>
          Inscrivez-vous !
        </Typography>
        <Typography className={style.logintext}>{TEXT_SIGN_UP}</Typography>
        <TextField
          {...{
            className: style.textField,
            type: "text",
            name: "lastName",
            label: "Votre nom",
            variant: "outlined",
            onChange: (e) => handleChange(e),
            onKeyDown: (e) => onKeyPressed(e),
          }}
        />
        <TextField
          {...{
            type: "text",
            name: "firstName",
            variant: "outlined",
            label: "Votre prénom",
            className: style.textField,
            onKeyDown: (e) => onKeyPressed(e),
            onChange: (e) => handleChange(e),
          }}
        />
        <TextField
          {...{
            className: style.textField,
            type: "text",
            name: "id",
            label: "Votre identifiant de connexion",
            variant: "outlined",
            onChange: (e) => handleChange(e),
            onKeyDown: (e) => onKeyPressed(e),
            onFocus: () => setIdError(true),
            onBlur: () => setIdError(false),
            error: idError && (input.id.length < 6 || input.id.length > 20),
            helperText:
              idError && (input.id.length < 6 || input.id.length > 20)
                ? "6 à 20 caractères au choix (facile à mémoriser par vous)"
                : "",
          }}
        />
        <TextField
          {...{
            className: style.textField,
            type: "password",
            name: "password",
            label: "Votre mot de passe",
            variant: "outlined",
            onChange: (e) => handleChange(e),
            onKeyDown: (e) => onKeyPressed(e),
            onFocus: () => setPasswordError(true),
            onBlur: () => setPasswordError(false),
            error:
              passwordError &&
              (isNaN(+input.password) || input.password.length !== 6),
            helperText:
              passwordError &&
              (isNaN(+input.password) || input.password.length !== 6)
                ? "6 chiffres au choix (facile à mémoriser par vous)"
                : "",
          }}
        />
        <ThemeProvider theme={theme}>
          <CustomButton
            {...{
              disabled: !activ,
              color: "primary",
              nameBtn: "VALIDER",
              variant: "contained",
              onClick: handleClick,
              className: style.btnconnect,
            }}
          />
        </ThemeProvider>

        <CustomButton
          {...{
            color: "primary",
            nameBtn: "ABANDONNER",
            variant: "outlined",
            onClick: () => history.push(LOGIN_URL),
            className: style.btnconnect,
            style: {
              marginTop: 30,
              marginBottom: 20,
              backgroundColor: "#FFEBA2",
              borderColor: "#FFEBA2",
              color: "#B48A4E",
            },
          }}
        />
      </Box>
      <CustomSnackBar
        {...{ open: open, setOpen: setOpen, message: message, type: type }}
      />
      <CustomBackdrop {...{ open: doSignUpLoading }} />
    </Box>
  );
};

export default CardSignUp;
