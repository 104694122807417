import { ApolloProvider } from "@apollo/client";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
  ACCUEIL_URL,
  FAQ_URL,
  HISTORY_URL,
  LOGIN_URL,
  SIGN_UP_URL,
} from "../../constant/url";
import { AuthProvider } from "../../provider/Authprovider";
import { BalanceProvider } from "../../provider/Balanceprovider";
import { client } from "../../service/ApolloClient";
import { PageAccueil } from "../Main/acceuil/newAccueil/NewAccueil";
import Homepage from "../Main/homepage/Home";
import SignUpPage from "../Main/singup/SignUp";
import { Transfert } from "../Main/transfert/Transfert";
import PrivateRoute from "../PrivateRoute/PrivateRoute";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#B48A4E",
    },
  },
  overrides: {
    MuiToolbar: {
      root: {
        backgroundColor: "#B48A4E!important",
      },
    },
  },
});

const Router = () => {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <BalanceProvider>
            <BrowserRouter>
              <Switch>
                <Route path={LOGIN_URL} exact component={Homepage} />
                <Route path={SIGN_UP_URL} exact component={SignUpPage} />
                <PrivateRoute
                  path={[ACCUEIL_URL, HISTORY_URL, FAQ_URL]}
                  exact
                  component={PageAccueil}
                />
                <PrivateRoute
                  path={"/accueil/transfert/:id"}
                  exact
                  component={Transfert}
                />
              </Switch>
            </BrowserRouter>
          </BalanceProvider>
        </AuthProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default Router;
