import { gql } from "@apollo/client";

export const USERS_FRAGMENTS_INFO = gql`
  fragment UsersInfo on User {
    id
    name
    lastName
    userName
    email
  }
`;
