import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    titledialog: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      color: "#fff",
    },

    actiondialog: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    btndialog: {
      boxShadow: "none",
      fontSize: 15,
      height: 42,
      letterSpacing: 0.57,
      lineHeight: "16px",
      textAlign: "center",
      margin: "0 6px",
      padding: 10,
      width: 190,
      "&:first-child": {
        marginLeft: 0,
      },
      "&:last-child": {
        marginRight: 0,
      },
    },
    btnCancel: {
      backgroundColor: "#FFFFFF",
      borderColor: "#B48A4E",
      borderWidth: 1,
      color: "#B48A4E",
    },
    btnconfirmer: {
      backgroundColor: "#B48A4E",
      color: "#fff",
    },
  })
);
